import { Card, Divider, Grid, Typography } from '@material-ui/core';
import React from 'react';
import { Details } from '../../interface';

interface Props extends Details {
  trackingNumber: string;
}

const CardDetails = ({
  sender,
  serviceType,
  paymentMethod,
  recipientPhone,
  pickupPhone,
  expectedDeliveryDate,
  trackingNumber,
}: Props) => (
  <Card
    sx={{
      height: {
        xs: '100%',
        md: '391px',
      },
      padding: {
        xs: '24px',
        md: '36px',
      },
      boxShadow: {
        xs: 'none',
        sm: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
      },
    }}>
    <Typography
      variant="h2"
      sx={{
        color: 'secondary.main',
        paddingBottom: {
          xs: 2,
          md: 3,
        },
      }}>
      Order Details
    </Typography>
    <Divider />
    <Grid container>
      <Grid item xs={12} sm={6} md={6}>
        <Grid item container direction="column" spacing={2}>
          <Grid item>
            <Typography
              variant="h3"
              sx={{
                paddingTop: 4,
              }}>
              Package Sender
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body" fontWeight={700}>
              {sender}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} sm={6} md={6}>
        <Grid item container direction="column" spacing={2}>
          <Grid item>
            <Typography
              variant="h3"
              sx={{
                paddingTop: 4,
              }}>
              Tracking Number
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body" fontWeight={700}>
              {trackingNumber}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} sm={6} md={6}>
        <Grid item container direction="column" spacing={2}>
          <Grid item>
            <Typography
              variant="h3"
              sx={{
                paddingTop: 4,
              }}>
              Payment Method
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body" fontWeight={700}>
              {paymentMethod
                .replace(/_/g, ' ')
                .replace(
                  /\w\S*/g,
                  (text: string) =>
                    text.charAt(0).toUpperCase() + text.substr(1).toLowerCase(),
                )}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} sm={6} md={6}>
        <Grid item container direction="column" spacing={2}>
          <Grid item>
            <Typography
              variant="h3"
              sx={{
                paddingTop: 4,
              }}>
              Service Type
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body" fontWeight={700}>
              {serviceType}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} sm={6} md={6}>
        <Grid item container direction="column" spacing={2}>
          <Grid item>
            <Typography
              variant="h3"
              sx={{
                paddingTop: 4,
              }}>
              Phone Number
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body" fontWeight={700}>
              {serviceType === 'Return Pickup'
                ? pickupPhone?.replace(/\S(?=\S{4})/g, '*')
                : recipientPhone?.replace(/\S(?=\S{4})/g, '*')}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Card>
);

export default CardDetails;
