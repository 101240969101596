import {
  Container,
  Grid,
  Theme,
  useMediaQuery,
  Button,
  Typography,
} from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useSwipeable } from 'react-swipeable';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowLeftRounded';
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowRightRounded';
import { ReactComponent as ContentCopyIcon } from '../images/content_copy.svg';
import Details from '../components/card/Details';
import History from '../components/card/History';
import Milestones from '../components/card/Milestones';
import Footer from '../components/Footer';
import { Order, OrderList } from '../interface';

interface Props {
  orders: OrderList;
}

const OrderPage = ({ orders }: Props) => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm'),
  );

  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const [copied, setCopied] = useState(false);
  const [current, setCurrent] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      setCopied(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, [copied]);

  const nextSlide = () => {
    setCurrent(current === orders.length - 1 ? 0 : current + 1);
  };

  const prevSlide = () => {
    setCurrent(current === 0 ? orders.length - 1 : current - 1);
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => nextSlide(),
    onSwipedRight: () => prevSlide(),
  });

  if (!Array.isArray(orders) || orders.length <= 0) {
    return null;
  }

  const copyToClipboard = () => {
    // Allow fallback if navigator clipboard is not available
    if (!navigator.clipboard) {
      const el = document.createElement('input');
      el.value = window.location.href;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      setCopied(true);
    } else {
      navigator.clipboard.writeText(window.location.href).then(() => {
        setCopied(true);
      });
    }
  };

  return (
    <>
      <Container
        component="main"
        sx={{
          display: 'flex',
          flex: 1,
          paddingY: {
            xs: '12px',
            sm: '24px',
            md: '36px',
          },
          ...(isMobile
            ? {
                backgroundColor: 'common.white',
                borderRadius: '16px',
                marginTop: '-12px',
              }
            : {
                marginTop: '-22.5vh',
              }),
        }}>
        <Grid container direction="column" spacing={4}>
          {orders.map((value: Order, index) => {
            const { details, history, milestones, trackingNumber } = value;

            return (
              <React.Fragment key={index}>
                {index === current && (
                  <Grid
                    {...handlers}
                    container
                    item
                    justifyContent="center"
                    spacing={2}>
                    <Grid
                      container
                      item
                      justifyContent="center"
                      alignItems="center">
                      <Grid item xs={2} sm="auto">
                        <Button
                          onClick={prevSlide}
                          sx={{
                            visibility:
                              orders.length > 1 && current !== 0
                                ? 'unset'
                                : 'hidden',
                          }}>
                          <ArrowBackRoundedIcon
                            sx={{
                              color: {
                                xs: 'secondary.main',
                                sm: 'white',
                              },
                              fontSize: {
                                xs: 48,
                                md: 64,
                              },
                            }}
                          />
                        </Button>
                      </Grid>

                      <Grid item xs={8} sm="auto">
                        <Typography
                          variant="h1"
                          align="center"
                          gutterBottom
                          sx={{
                            wordWrap: 'break-word',
                            color: {
                              xs: 'secondary.main',
                              sm: 'white',
                              md: 'white',
                            },
                            mt: {
                              xs: 3,
                              sm: 0,
                            },
                            mb: 0,
                            minWidth: {
                              sm: '512px',
                            },
                          }}>
                          {trackingNumber}
                        </Typography>
                      </Grid>

                      <Grid item xs={2} sm="auto">
                        <Button
                          onClick={nextSlide}
                          sx={{
                            visibility:
                              orders.length > 1 && current !== orders.length - 1
                                ? 'unset'
                                : 'hidden',
                          }}>
                          <ArrowForwardRoundedIcon
                            sx={{
                              color: {
                                xs: 'secondary.main',
                                sm: 'white',
                              },
                              fontSize: {
                                xs: 48,
                                md: 64,
                              },
                            }}
                          />
                        </Button>
                      </Grid>
                    </Grid>

                    <Grid item flex={{ xs: 1, md: 0.75 }}>
                      <Milestones
                        milestones={
                          milestones === 'Pickup Pending'
                            ? 'Order Created'
                            : milestones
                        }
                        trackingNumber={trackingNumber}
                        serviceType={details.serviceType}
                      />
                    </Grid>

                    <Grid container item spacing={2}>
                      <Grid item order={{ xs: 2, md: 1 }} xs={12} md={6}>
                        <Details {...details} trackingNumber={trackingNumber} />
                        <Button
                          variant="contained"
                          fullWidth
                          onClick={copyToClipboard}
                          startIcon={isDesktop ? <ContentCopyIcon /> : null}
                          sx={{
                            height: '64px',
                            margin: {
                              xs: '24px 0px',
                              md: '24px 0px 0px 0px',
                            },
                          }}>
                          {!copied
                            ? 'Copy Tracking Link to Clipboard'
                            : 'Copied to Clipboard!'}
                        </Button>
                      </Grid>

                      <Grid item order={{ xs: 1, md: 2 }} xs={12} md={6}>
                        <History
                          history={history}
                          serviceType={details.serviceType}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </React.Fragment>
            );
          })}
        </Grid>
      </Container>
      {isDesktop && <Footer />}
    </>
  );
};

export default OrderPage;
