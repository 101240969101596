import { Box, Container, Grid, Link, Typography } from '@material-ui/core';
import { ReactComponent as Air21 } from '../images/air21-logo-white.svg';

const Footer = () => (
  <Box component="footer" bgcolor="secondary.main" display="flex" height="12vh">
    <Container sx={{ display: 'flex' }}>
      <Grid container alignItems="center" spacing={{ md: 3, lg: 5 }}>
        <Grid item md={3}>
          <Link href="/" target="_self">
            <Air21 height={40} />
          </Link>
        </Grid>

        <Grid container item justifyContent="flex-end" md={9} spacing={5}>
          <Grid item >
          <Typography component="span" color="common.white">
            To learn more about our services, visit{' '}
            <Link
              href="https://air21.com.ph"
              rel="noreferrer"
              target="_blank"
              fontWeight="700"
              underline="none"
              color="primary.main">
              air21.com.ph
            </Link>
            </Typography>
          </Grid>

        </Grid>
      </Grid>
    </Container>
  </Box>
);

export default Footer;
