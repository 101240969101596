import {
  Button,
  CircularProgress,
  FormHelperText,
  IconButton,
  InputBase,
  Paper,
  Tooltip,
} from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import {
  Control,
  Controller,
  DeepMap,
  ErrorOption,
  FieldError,
  FieldValues,
} from 'react-hook-form';

interface Props {
  control: Control<FieldValues>;
  defaultValue: string;
  errors: DeepMap<FieldValues, FieldError>;
  isMobile: boolean;
  loading: boolean;
  setFormErrors: (
    name: string | `${string}.${string}` | `${string}.${number}`,
    error: ErrorOption,
    options?:
      | {
          shouldFocus: boolean;
        }
      | undefined,
  ) => void;
}

const SearchBar = ({
  control,
  defaultValue,
  errors,
  isMobile,
  loading,
  setFormErrors,
}: Props) => (
  <>
    <Paper
      sx={{
        alignItems: 'center',
        display: 'flex',
        height: 48,
        p: '2px 4px',
      }}>
      <Tooltip title="You can search up to Five (5) Tracking Numbers">
        <IconButton color="primary" size="small" aria-label="Information">
          <InfoOutlinedIcon />
        </IconButton>
      </Tooltip>
      <Controller
        name="searchString"
        control={control}
        defaultValue={defaultValue}
        rules={{
          required: {
            value: true,
            message: 'Tracking Number is Required',
          },
          pattern: {
            value: /(^[A-Z0-9]+([-,][A-Z0-9]+)+$)/g,
            message: 'Invalid Tracking Number',
          },
        }}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <InputBase
            inputProps={{ 'aria-label': 'Enter Tracking Number' }}
            onChange={(e) => {
              const value = e.target.value.toUpperCase();
              const trackingNumbers = value.split(',');

              if (trackingNumbers.length > 5) {
                setFormErrors('searchString', {
                  type: 'manual',
                  message: 'Maximum Count of Tracking Numbers Reached',
                });
              } else {
                setFormErrors('searchString', {
                  type: 'manual',
                  message: undefined,
                });
              }

              return onChange(value);
            }}
            placeholder="Enter Tracking Number"
            value={value.toUpperCase()}
            sx={{
              color: 'secondary.main',
              flex: 1,
              ml: 1,
            }}
          />
        )}
      />
      {!isMobile ? (
        <Button
          variant="contained"
          type="submit"
          sx={{
            display: { xs: 'none', sm: 'inline-flex' },
            width: '120px',
          }}>
          {loading ? <CircularProgress color="inherit" size={28} /> : 'Search'}
        </Button>
      ) : undefined}
    </Paper>

    {errors.searchString && (
      <FormHelperText sx={{ color: 'error.main' }}>
        {errors.searchString?.message}
      </FormHelperText>
    )}

    {isMobile ? (
      <Button
        variant="contained"
        type="submit"
        fullWidth
        sx={{
          marginY: 1,
        }}>
        {loading ? <CircularProgress color="inherit" size={28} /> : 'Search'}
      </Button>
    ) : undefined}
  </>
);

export default SearchBar;
