import AnimatedDeliveryInProgress from '../images/milestones/animated/delivery_in_progress.png';
import AnimatedOrderClosed from '../images/milestones/animated/order_closed.png';
import AnimatedOrderCreated from '../images/milestones/animated/order_created.png';
import AnimatedPackageDelivered from '../images/milestones/animated/package_delivered.png';
import AnimatedPackageForReturnToSender from '../images/milestones/animated/package_for_return_to_sender.png';
import AnimatedPackageReturned from '../images/milestones/animated/package_returned.png';
import AnimatedProcessingOrder from '../images/milestones/animated/processing_order.png';
import AnimatedPickupSuccess from '../images/milestones/animated/pickup_success.png';
import ColoredDeliveryInProgress from '../images/milestones/colored/delivery_in_progress.png';
import ColoredOrderClosed from '../images/milestones/colored/order_closed.png';
import ColoredOrderCreated from '../images/milestones/colored/order_created.png';
import ColoredPackageDelivered from '../images/milestones/colored/package_delivered.png';
import ColoredPackageForReturnToSender from '../images/milestones/colored/package_for_return_to_sender.png';
import ColoredPackageReturned from '../images/milestones/colored/package_returned.png';
import ColoredProcessingOrder from '../images/milestones/colored/processing_order.png';
import ColoredPickupSuccess from '../images/milestones/colored/pickup_success.png';
import GrayscaledDeliveryInProgress from '../images/milestones/grayscaled/delivery_in_progress.png';
import GrayscaledOrderClosed from '../images/milestones/grayscaled/order_closed.png';
import GrayscaledOrderCreated from '../images/milestones/grayscaled/order_created.png';
import GrayscaledPackageDelivered from '../images/milestones/grayscaled/package_delivered.png';
import GrayscaledPackageForReturnToSender from '../images/milestones/grayscaled/package_for_return_to_sender.png';
import GrayscaledPackageReturned from '../images/milestones/grayscaled/package_returned.png';
import GrayscaledProcessingOrder from '../images/milestones/grayscaled/processing_order.png';
import GrayscaledPickupSuccess from '../images/milestones/grayscaled/pickup_success.png';

const Milestones = {
  OrderCreated: {
    active: AnimatedOrderCreated,
    completed: ColoredOrderCreated,
    inactive: GrayscaledOrderCreated,
  },
  PickupPending: {
    active: AnimatedDeliveryInProgress,
    completed: ColoredDeliveryInProgress,
    inactive: GrayscaledDeliveryInProgress,
  },
  PickupFailed: {
    active: AnimatedOrderClosed,
    completed: ColoredOrderClosed,
    inactive: GrayscaledOrderClosed,
  },
  PickupSuccess: {
    active: AnimatedPickupSuccess,
    completed: ColoredPickupSuccess,
    inactive: GrayscaledPickupSuccess,
  },
  ProcessingOrder: {
    active: AnimatedProcessingOrder,
    completed: ColoredProcessingOrder,
    inactive: GrayscaledProcessingOrder,
  },
  DeliveryInProgress: {
    active: AnimatedDeliveryInProgress,
    completed: ColoredDeliveryInProgress,
    inactive: GrayscaledDeliveryInProgress,
  },
  PackageDelivered: {
    active: AnimatedPackageDelivered,
    completed: ColoredPackageDelivered,
    inactive: GrayscaledPackageDelivered,
  },
  PackageForReturnToSender: {
    active: AnimatedPackageForReturnToSender,
    completed: ColoredPackageForReturnToSender,
    inactive: GrayscaledPackageForReturnToSender,
  },
  PackageReturned: {
    active: AnimatedPackageReturned,
    completed: ColoredPackageReturned,
    inactive: GrayscaledPackageReturned,
  },
  OrderClosed: {
    active: AnimatedOrderClosed,
    completed: ColoredOrderClosed,
    inactive: GrayscaledOrderClosed,
  },
  ReturnPickupAccepted: {
    active: AnimatedOrderCreated,
    completed: ColoredOrderCreated,
    inactive: GrayscaledOrderCreated,
  },
  ReturnPickupPending: {
    active: AnimatedProcessingOrder,
    completed: ColoredProcessingOrder,
    inactive: GrayscaledProcessingOrder,
  },
  ReturnPickupFailed: {
    active: AnimatedOrderClosed,
    completed: ColoredOrderClosed,
    inactive: GrayscaledOrderClosed,
  },
  ReturnPickupSuccess: {
    active: AnimatedPickupSuccess,
    completed: ColoredPickupSuccess,
    inactive: GrayscaledPickupSuccess,
  },
};

// Milestones and Service Type mapping base on LMS
export const ServiceTypeJourney = {
  'Forward Delivery': [
    [
      { 'Order Created': Milestones.OrderCreated },
      { 'Processing Order': Milestones.ProcessingOrder },
      { 'Delivery in Progress': Milestones.DeliveryInProgress },
      { 'Package Delivered': Milestones.PackageDelivered },
    ],
    [
      { 'Package for Return to Sender': Milestones.PackageForReturnToSender },
      { 'Package Returned': Milestones.PackageReturned },
    ],
    [{ 'Order Closed': Milestones.OrderClosed }],
    [
      { 'Return Pickup Accepted': Milestones.ReturnPickupAccepted },
      { 'Return Pickup Pending': Milestones.ReturnPickupPending },
      { 'Return Pickup Success': Milestones.ReturnPickupSuccess },
    ],
    [
      { 'Return Pickup Accepted': Milestones.ReturnPickupAccepted },
      { 'Return Pickup Pending': Milestones.ReturnPickupPending },
      { 'Return Pickup Failed': Milestones.ReturnPickupFailed },
    ],
  ],
  'Return Pickup': [
    [
      { 'Order Created': Milestones.OrderCreated },
      { 'Processing Order': Milestones.ProcessingOrder },
      { 'Delivery in Progress': Milestones.DeliveryInProgress },
      { 'Package Delivered': Milestones.PackageDelivered },
    ],
    [
      { 'Package for Return to Sender': Milestones.PackageForReturnToSender },
      { 'Package Returned': Milestones.PackageReturned },
    ],
    [{ 'Order Closed': Milestones.OrderClosed }],
    [
      { 'Return Pickup Accepted': Milestones.ReturnPickupAccepted },
      { 'Return Pickup Pending': Milestones.ReturnPickupPending },
      { 'Return Pickup Success': Milestones.ReturnPickupSuccess },
    ],
    [
      { 'Return Pickup Accepted': Milestones.ReturnPickupAccepted },
      { 'Return Pickup Pending': Milestones.ReturnPickupPending },
      { 'Return Pickup Failed': Milestones.ReturnPickupFailed },
    ],
  ],
  'Return To Merchant': [
    [
      { 'Order Created': Milestones.OrderCreated },
      { 'Processing Order': Milestones.ProcessingOrder },
      { 'Delivery in Progress': Milestones.DeliveryInProgress },
      { 'Package Delivered': Milestones.PackageDelivered },
    ],
    [
      { 'Package for Return to Sender': Milestones.PackageForReturnToSender },
      { 'Package Returned': Milestones.PackageReturned },
    ],
    [{ 'Order Closed': Milestones.OrderClosed }],
    [
      { 'Return Pickup Accepted': Milestones.ReturnPickupAccepted },
      { 'Return Pickup Pending': Milestones.ReturnPickupPending },
      { 'Return Pickup Success': Milestones.ReturnPickupSuccess },
    ],
    [
      { 'Return Pickup Accepted': Milestones.ReturnPickupAccepted },
      { 'Return Pickup Pending': Milestones.ReturnPickupPending },
      { 'Return Pickup Failed': Milestones.ReturnPickupFailed },
    ],
  ],
  Marketplace: [
    [
      { 'Order Created': Milestones.OrderCreated },
      { 'Pickup Success': Milestones.PickupSuccess },
      { 'Processing Order': Milestones.ProcessingOrder },
      { 'Delivery in Progress': Milestones.DeliveryInProgress },
      { 'Package Delivered': Milestones.PackageDelivered },
    ],
    [
      { 'Order Created': Milestones.OrderCreated },
      { 'Pickup Failed': Milestones.PickupFailed },
      { 'Processing Order': Milestones.ProcessingOrder },
      { 'Delivery in Progress': Milestones.DeliveryInProgress },
      { 'Package Delivered': Milestones.PackageDelivered },
    ],
    [
      { 'Package for Return to Sender': Milestones.PackageForReturnToSender },
      { 'Package Returned': Milestones.PackageReturned },
    ],
    [{ 'Order Closed': Milestones.OrderClosed }],
    [
      { 'Return Pickup Accepted': Milestones.ReturnPickupAccepted },
      { 'Return Pickup Pending': Milestones.ReturnPickupPending },
      { 'Return Pickup Success': Milestones.ReturnPickupSuccess },
    ],
    [
      { 'Return Pickup Accepted': Milestones.ReturnPickupAccepted },
      { 'Return Pickup Pending': Milestones.ReturnPickupPending },
      { 'Return Pickup Failed': Milestones.ReturnPickupFailed },
    ],
  ],
  Retail: [
    [
      { 'Order Created': Milestones.OrderCreated },
      { 'Pickup Success': Milestones.PickupSuccess },
      { 'Processing Order': Milestones.ProcessingOrder },
      { 'Delivery in Progress': Milestones.DeliveryInProgress },
      { 'Package Delivered': Milestones.PackageDelivered },
    ],
    [
      { 'Order Created': Milestones.OrderCreated },
      { 'Pickup Failed': Milestones.PickupFailed },
      { 'Processing Order': Milestones.ProcessingOrder },
      { 'Delivery in Progress': Milestones.DeliveryInProgress },
      { 'Package Delivered': Milestones.PackageDelivered },
    ],
    [
      { 'Package for Return to Sender': Milestones.PackageForReturnToSender },
      { 'Package Returned': Milestones.PackageReturned },
    ],
    [{ 'Order Closed': Milestones.OrderClosed }],
    [
      { 'Return Pickup Accepted': Milestones.ReturnPickupAccepted },
      { 'Return Pickup Pending': Milestones.ReturnPickupPending },
      { 'Return Pickup Success': Milestones.ReturnPickupSuccess },
    ],
    [
      { 'Return Pickup Accepted': Milestones.ReturnPickupAccepted },
      { 'Return Pickup Pending': Milestones.ReturnPickupPending },
      { 'Return Pickup Failed': Milestones.ReturnPickupFailed },
    ],
  ],
};

export const ExcludedOrderStatusByServiceType = {
  Retail: ['PICKUP_PENDING'],
  Marketplace: ['PICKUP_PENDING'],
};
