import { Button, Container, Grid, Typography } from '@material-ui/core';
import React from 'react';
import { ReactComponent as Background } from '../images/not-found.svg';

const NotFoundPage = () => (
  <Container
    sx={{
      display: 'flex',
      flex: 1,
      backgroundImage: `url(${Background})`,
      backgroundPosition: '50% 50%',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      maxHeight: '100vh',
    }}>
    <Grid container>
      <Grid item xs={12} md={6} order={{ xs: 2, md: 1 }} display="flex">
        <Background width="100%" />
      </Grid>
      <Grid
        container
        xs={12}
        item
        md={6}
        order={{ xs: 1, md: 2 }}
        direction="column"
        alignItems="center"
        alignSelf="center">
        <Grid item>
          <Typography variant="title" textAlign="center">
            We couldn't find the page you were looking for.
          </Typography>
        </Grid>
        <Grid item>
          <Button variant="outlined" href="/">
            Return to Home
          </Button>
        </Grid>
      </Grid>
    </Grid>
  </Container>
);
export default NotFoundPage;
