import { createTheme, responsiveFontSizes } from '@material-ui/core/styles';

declare module '@material-ui/core/styles' {
  interface TypographyVariants {
    title: React.CSSProperties;
    subtitle: React.CSSProperties;
    body: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    title?: React.CSSProperties;
    subtitle?: React.CSSProperties;
    body?: React.CSSProperties;
  }
}

declare module '@material-ui/core/Typography' {
  interface TypographyPropsVariantOverrides {
    title: true;
    subtitle: true;
    body: true;
    subtitle1: false;
    subtitle2: false;
    h4: false;
    h5: false;
    h6: false;
    body1: false;
    body2: false;
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#4FB2A9',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#712C81',
      contrastText: '#ffffff',
    },
    success: {
      main: '#11D045',
      contrastText: '#ffffff',
    },
    error: {
      main: '#FF6961',
      contrastText: '#ffffff',
    },
    warning: {
      main: '#F4CD29',
      contrastText: '#212121',
    },
  },
  typography: {
    fontFamily: 'Open Sans, sans-serif',
    title: { fontSize: '4rem', fontWeight: 700 },
    subtitle: { fontSize: '3rem', fontWeight: 400 },
    h1: { fontSize: '1.75rem', fontWeight: 700 },
    h2: { fontSize: '1.25rem', fontWeight: 700, color: '#1F1F1F' },
    h3: { fontSize: '1rem', fontWeight: 700, color: '#A97FB3' },
    body: { fontSize: '1rem', fontWeight: 400, color: '#1F1F1F' },
    button: { fontSize: '1rem', fontWeight: 400, letterSpacing: '0.1em' },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '#root': {
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: { fontSize: '36px' },
        fontSizeSmall: { fontSize: '24px' },
        fontSizeLarge: { fontSize: '48px' },
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          title: 'h1',
          subtitle: 'h2',
          body: 'p',
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        root: {
          '& .Mui-active': {
            color: '#ee7626 !important',
            fontWeight: '700 !important',
          },
          '& .Mui-completed': {
            color: '#ee7626 !important',
            fontWeight: '700 !important',
          },
        },
      },
    },
  },
});

export default responsiveFontSizes(theme);
