export const convertParamsToString = (
  url: string,
  params: Record<string, unknown>,
) => {
  if (Object.keys(params).length === 0) {
    return url;
  }

  const parameters = new URLSearchParams();

  Object.entries(params).forEach(([key, value]) => {
    if (value) {
      parameters.set(key, value as string);
    }
  });

  return `${url}?${parameters.toString()}`;
};
