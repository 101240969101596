import {
  Card,
  Divider,
  Typography,
  Button,
  Grid,
  Theme,
  useMediaQuery,
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from '@material-ui/lab';
import { useState } from 'react';
import { DateTime } from 'luxon';
import { HistoryList } from '../../interface';
import { ExcludedOrderStatusByServiceType } from '../../utils/constants';

interface Props {
  history: HistoryList;
  serviceType: string;
}

interface ShowProps {
  children: JSX.Element[];
}

const ShowMore = ({ children }: ShowProps) => {
  const items = children;
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  return (
    <div>
      {isDesktop ? (
        <div>
          {isReadMore ? items.slice(0, 5) : items}
          <Grid
            container
            sx={{
              display: 'flex',
              justifyContent: 'center',
              marginBottom: 0,
            }}>
            {items.length > 5 && (
              <Button
                onClick={toggleReadMore}
                sx={{
                  p: 0,
                  textDecoration: 'underline',
                }}>
                {isReadMore ? 'show more' : ' show less'}
              </Button>
            )}
          </Grid>
        </div>
      ) : (
        <div>{items}</div>
      )}
    </div>
  );
};

const CardHistory = ({ history, serviceType }: Props) => {
  const excludedStatus = Object.values(
    Object.fromEntries(
      Object.entries(ExcludedOrderStatusByServiceType).filter(
        ([key, value]) => key === serviceType,
      ),
    ),
  ).flat();

  const filteredHistory = history.filter(
    (value) => !excludedStatus.includes(value.status),
  );

  return (
    <>
      <Card
        sx={{
          height: '100%',
          padding: {
            xs: '24px',
            md: '36px',
          },
          boxShadow: {
            xs: 'none',
            sm: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
          },
        }}>
        <Typography
          variant="h2"
          sx={{
            color: 'secondary.main',
            paddingBottom: {
              xs: 2,
              md: 3,
            },
          }}>
          Order History
        </Typography>
        <Divider />
        <Timeline
          sx={{
            padding: '6px 0',
            mb: 0,
          }}>
          <ShowMore>
            {filteredHistory
              .map((data, index) => (
                <TimelineItem key={index}>
                  <TimelineOppositeContent
                    sx={{
                      width: '100%',
                      maxWidth: {
                        xs: '100px',
                        sm: '140px',
                      },
                      flex: 'revert',
                      textAlign: 'left',
                      padding: '6px 16px 6px 0px',
                    }}>
                    <Typography
                      variant="body"
                      component="div"
                      sx={{
                        color:
                          index === filteredHistory.length - 1
                            ? grey[900]
                            : grey[400],
                        fontWeight: 600,
                        fontSize: {
                          xs: '0.875rem',
                          sm: '1rem',
                        },
                      }}>
                      {DateTime.fromFormat(
                        data.lastUpdated,
                        'ddLLLyyyy hh:mm:ss a',
                      ).toFormat('LLL. dd, yyyy hh:mm a')}
                    </Typography>
                  </TimelineOppositeContent>

                  <TimelineSeparator>
                    <TimelineDot
                      sx={{
                        ...(index === filteredHistory.length - 1 && {
                          backgroundColor: 'primary.main',
                        }),
                      }}
                    />

                    {index !== 0 ? (
                      <TimelineConnector
                        sx={{
                          borderLeftStyle: 'dashed',
                          borderWidth: 2,
                          backgroundColor: 'common.white',
                          color: grey[400],
                        }}
                      />
                    ) : null}
                  </TimelineSeparator>

                  <TimelineContent>
                    <Typography
                      component="div"
                      variant={
                        index === filteredHistory.length - 1 ? 'h3' : 'body'
                      }
                      {...(index !== filteredHistory.length - 1 && {
                        sx: {
                          color: grey[400],
                          fontWeight: 600,
                        },
                      })}>
                      {data.eventName}
                      <Typography
                        variant="body"
                        sx={{
                          paddingTop: 1,
                          paddingBottom: 2,
                          color:
                            index === filteredHistory.length - 1
                              ? 'secondary.main'
                              : grey[400],
                          fontSize: {
                            xs: '0.875rem',
                            sm: '1rem',
                          },
                        }}>
                        {data.eventMessage}
                      </Typography>
                    </Typography>
                  </TimelineContent>
                </TimelineItem>
              ))
              .reverse()}
          </ShowMore>
        </Timeline>
      </Card>
    </>
  );
};

export default CardHistory;
