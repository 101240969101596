/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import {
  Alert,
  Box,
  Button,
  Container,
  Grid,
  Link,
  Snackbar,
  Theme,
  useMediaQuery,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useQueryParams } from 'hookrouter';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { ReactComponent as Air21 } from '../images/air21tracking.svg';
import Background from '../images/header.jpeg';
import { History, Order, OrderList, Response } from '../interface';
import request from '../utils/api';
import SearchBar from './SearchBar';

interface Props {
  orders: OrderList;
  setOrders: React.Dispatch<React.SetStateAction<OrderList>>;
}

const Header = ({ orders, setOrders }: Props) => {
  const [queryParams, setQueryParams] = useQueryParams();
  const { q = '' } = queryParams;

  const {
    handleSubmit,
    control,
    reset,
    setError: setFormErrors,
    formState: { errors: formErrors },
  } = useForm();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm'),
  );

  const isTablet = useMediaQuery((theme: Theme) =>
    theme.breakpoints.between('sm', 'md'),
  );

  const onClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const onReturn = () => {
    setQueryParams({}, true);
    setOrders([]);
    reset();
  };

  const onSearch = async (form: Record<string, unknown>) => {
    setLoading(true);

    try {
      const { searchString } = form;

      if (typeof searchString === 'string') {
        if (searchString.length === 0) {
          setFormErrors('searchString', {
            type: 'manual',
            message: 'Tracking Number is Required',
          });
          setOrders([]);
        } else {
          let trackingNumbers = searchString.split(',');

          if (trackingNumbers.length > 5) {
            setFormErrors('searchString', {
              type: 'manual',
              message: 'Maximum Count of Tracking Numbers Reached',
            });
          } else {
            const body: Response[] = await request('orders', {
              query: { tracking_numbers: searchString },
            });

            let order: Order;

            if (body.length === 0) {
              setError('Tracking Number Not Found');
              setOpen(true);
              setOrders([]);
            } else {
              setOrders(
                body.map((value) => {
                  Object.entries(value).forEach(([key, details]) => {
                    let latestMilestone: string = '';
                    const statuses: History[] = [];

                    trackingNumbers = trackingNumbers.filter(
                      (value) => key !== value,
                    );

                    details.history.forEach((history, index) => {
                      Object.entries(history).forEach(([status, events]) => {
                        statuses.push({
                          status,
                          eventMessage: events['event-message'],
                          eventName: events['event-name'],
                          lastUpdated: events['last-updated'],
                        });

                        if (index === details.history.length - 1) {
                          latestMilestone = events['event-milestone'];
                        }
                      });

                      order = {
                        trackingNumber: key,
                        details: {
                          sender: details.info.sender,
                          paymentMethod: details.info['payment-method'],
                          expectedDeliveryDate:
                            details.info['expected-delivery-date'],
                          pickupPhone: details.info['pickup-phone'],
                          recipientPhone: details.info['recipient-phone'],
                          serviceType: details.info['service-type'],
                        },
                        milestones: latestMilestone,
                        history: statuses,
                      };
                    });
                  });

                  setQueryParams({ q: searchString });

                  setFormErrors('searchString', {
                    type: 'manual',
                    message: undefined,
                  });

                  return order;
                }),
              );

              if (trackingNumbers.length > 0) {
                setError(
                  `Tracking Number Not Found: ${trackingNumbers.join(', ')}`,
                );
                setOpen(true);
              }
            }
          }
        }
      }
    } catch (error) {
      setError('Internal Server Error');
      setOpen(true);
      setOrders([]);
    }

    setQueryParams({});
    setLoading(false);
  };

  return (
    <Box
      component="header"
      display="flex"
      height={{
        sm: '30vh',
        ...(orders.length > 0 ? { xs: '30vh' } : { xs: '50vh' }),
      }}
      sx={{
        backgroundImage: `url(${Background})`,
        backgroundPosition: '50% 50%',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={3000}
        open={open}
        onClose={onClose}>
        <Alert onClose={onClose} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>
      <Container
        component="form"
        onSubmit={handleSubmit(onSearch)}
        sx={{ display: 'flex' }}>
        {orders.length > 0 ? (
          <Grid container marginY={2}>
            <Grid item xs={12} sm={1} md={2}>
              <Button onClick={onReturn}>
                {isTablet ? (
                  ''
                ) : (
                  <Air21
                    css={css`
                      @media (min-width: ${theme.breakpoints.values.xs}px) {
                        height: 40px;
                      }
                      @media (min-width: ${theme.breakpoints.values.lg}px) {
                        height: 40px;
                        position: absolute;
                        top: 2%;
                        left: -130px;
                      }
                    `}
                  />
                )}
              </Button>
            </Grid>

            <Grid item xs={12} sm={10} md={8}>
              <SearchBar
                control={control}
                defaultValue={q.replace(/[^a-zA-Z0-9-%]/g, ',')}
                errors={formErrors}
                isMobile={isMobile}
                loading={loading}
                setFormErrors={setFormErrors}
              />
            </Grid>
          </Grid>
        ) : (
          <Grid container direction="column" justifyContent="space-evenly">
            <Grid container item spacing={0} justifyContent="center">
              <Grid item>
                <Link href="/" target="_self">
                  {isMobile ? (
                    <Air21
                      css={css`
                        height: 72px;
                      `}
                    />
                  ) : (
                    <Air21
                      css={css`
                        @media (min-width: ${theme.breakpoints.values.sm}px) {
                          height: 48px;
                        }
                        @media (min-width: ${theme.breakpoints.values.md}px) {
                          height: 56px;
                        }
                        @media (min-width: ${theme.breakpoints.values.lg}px) {
                          height: 64px;
                        }
                      `}
                    />
                  )}
                </Link>
              </Grid>
            </Grid>

            <Grid container item justifyContent="center" spacing={0}>
              <Grid item xs={12} sm={8}>
                <SearchBar
                  control={control}
                  defaultValue={q.replace(/[^a-zA-Z0-9-%]/g, ',')}
                  errors={formErrors}
                  isMobile={isMobile}
                  loading={loading}
                  setFormErrors={setFormErrors}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
      </Container>
    </Box>
  );
};

export default Header;
