import {
  Box,
  Container,
  Grid,
  IconButton,
  Link,
  Modal,
  Theme,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import React, { useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import Header from '../components/Header';
import { OrderList } from '../interface';
import OrderPage from './Order';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
};

const advisoryImage = process.env.REACT_APP_ADVISORY;


const SearchPage = () => {
  const [orders, setOrders] = useState<OrderList>([]);
  const [open, setOpen] = React.useState(true);
  const handleClose = () => setOpen(false);
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm'),
  );

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Container component="main">
          <Box sx={style}>
            <Box>
              <IconButton onClick={handleClose} sx={{
                position: 'absolute',
                right: 1,
                top: 1,
                color: '#fff',
                "&.MuiButtonBase-root:hover": {
                  bgcolor: "transparent"
                }
              }}>

                <CloseIcon />
              </IconButton>
              <Box component="img" src={advisoryImage} alt='advisory' sx={{ width: { xs: "100vw", md: '35vw' } }}/>
            </Box>
          </Box>
        </Container>
      </Modal>
      <Header orders={orders} setOrders={setOrders} />
      {orders.length > 0 ? (
        <OrderPage orders={orders} />
      ) : (
        <Container
          component="main"
          sx={{
            display: 'flex',
            flex: 1,
            paddingTop: '48px',
            paddingBottom: '64px',
            ...(isMobile && {
              backgroundColor: 'common.white',
              borderRadius: '16px',
              marginTop: '-24px',
            }),
          }}>
          <Grid
            container
            alignContent="center"
            direction="column"
            justifyContent="space-between"
            marginX="auto"
            spacing={0}>
            <Grid item textAlign="center">
              <Typography component="span" color="secondary.main">
                To learn more about our services, visit{' '}
                <Link
                  href="https://air21.com.ph"
                  rel="noreferrer"
                  target="_blank"
                  fontWeight="700"
                  underline="none"
                  color="primary.main">
                  air21.com.ph
                </Link>
              </Typography>
            </Grid>

            <Grid
              container
              item
              alignSelf="center"
              direction="column"
              rowSpacing={4}
              spacing={0}
              width="280px" />
          </Grid>
        </Container>
      )}
    </>
  );
};

export default SearchPage;
