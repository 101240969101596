/** @jsxImportSource @emotion/react */
import {
  Card,
  Step,
  StepConnector,
  stepConnectorClasses,
  StepIconProps,
  StepLabel,
  Stepper,
  styled,
  Theme,
  useMediaQuery,
} from '@material-ui/core';
import React from 'react';
import { ServiceTypeJourney } from '../../utils/constants';

const Connector = styled(StepConnector)(() => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    '@media (min-width: 600px)': {
      top: 50,
      left: 'calc(-25% + 15px)',
      right: 'calc(80% + 10px)',
      position: 'absolute',
    },
    '@media (min-width: 1280px)': {
      top: 50,
      left: 'calc(-28% + 10px)',
      right: 'calc(70% + 20px)',
      position: 'absolute',
    },
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#ee7626',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#ee7626',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: '#9e9e9e',
    borderWidth: '5px',
  },
}));

interface Props {
  milestones: string;
  trackingNumber: string;
  serviceType: string;
}

const CardMilestones = ({ milestones, trackingNumber, serviceType }: Props) => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm'),
  );

  const getActiveSteps = () => {
    // Get the journeys based on service type and flatten it
    const journeys = Object.values(
      Object.fromEntries(
        Object.entries(ServiceTypeJourney).filter(
          ([key, value]) => key === serviceType,
        ),
      ),
    ).flat();

    for (let i = 0; i < journeys.length; i++) {
      for (let j = 0; j < journeys[i].length; j++) {
        if (Object.keys(journeys[i][j])[0] === milestones) {
          if (isMobile) {
            return { steps: [journeys[i][j]], active: j };
          }

          return { steps: journeys[i], active: j };
        }
      }
    }

    return { steps: [], active: 0 };
  };

  const { steps, active } = getActiveSteps();

  const CustomStepIcon = ({
    active: activated,
    completed,
    icon,
  }: StepIconProps) => {
    const animatedIcons: { [index: string]: React.ReactElement } = {};
    const grayscaledIcons: { [index: string]: React.ReactElement } = {};
    const coloredIcons: { [index: string]: React.ReactElement } = {};

    for (let i = 0; i < steps.length; i++) {
      Object.entries(steps[i]).forEach(([key, value]) => {
        Object.assign(animatedIcons, {
          [i + 1]: <img src={value.active} alt={key} />,
        });
        Object.assign(grayscaledIcons, {
          [i + 1]: <img src={value.inactive} alt={key} />,
        });
        Object.assign(coloredIcons, {
          [i + 1]: <img src={value.completed} alt={key} />,
        });
      });
    }

    return (
      <div css={{ '& img': { height: '96px' } }}>
        {(() => {
          if (isMobile) {
            return coloredIcons[String(icon)];
          }

          if (completed) {
            return coloredIcons[String(icon)];
          }

          return activated
            ? animatedIcons[String(icon)]
            : grayscaledIcons[String(icon)];
        })()}
      </div>
    );
  };

  return (
    <>
      <Card
        sx={{
          width: {
            lg: '1156px',
          },
          p: {
            xs: '24px',
            sm: '24px 0px',
            lg: '24px',
          },
          boxShadow: {
            xs: 'none',
            sm: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
          },
        }}>
        <Stepper alternativeLabel activeStep={active} connector={<Connector />}>
          {steps.map((value, index) => (
            <Step
              key={index}
              sx={{
                px: {
                  sm: '12px',
                  md: '24px',
                  lg: '48px',
                },
              }}>
              <StepLabel StepIconComponent={CustomStepIcon}>
                {Object.keys(value)[0]}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Card>
    </>
  );
};

export default CardMilestones;
