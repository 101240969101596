import { convertParamsToString } from './url-format';

const API_URL = `${process.env.REACT_APP_API_URL}`;
const URL_REGEX =
  /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi;

interface Options {
  method?: string;
  body?: Record<string, unknown> | typeof FormData | null;
  query?: Record<string, unknown>;
}

interface Request {
  method: string;
  headers: {
    Accept: string;
    Authorization?: string;
    'Content-Type'?: string;
  };
  body?: BodyInit | null;
}

class ApiUrlException extends Error {
  constructor(message: string) {
    super(message);
    Object.setPrototypeOf(this, ApiUrlException.prototype);
  }
}

/* eslint-disable  @typescript-eslint/no-explicit-any */
const safeJsonParse = (message: any) => {
  try {
    return JSON.parse(message);
  } catch (err) {
    if (err instanceof SyntaxError) {
      return 'Cross-Origin Request Blocked';
    }

    return err;
  }
};

const request = async (url: string, options?: Options) => {
  let newUrl: string;
  let method = 'get';
  let body;

  const checkUrl = url.match(URL_REGEX);

  if (checkUrl) {
    [newUrl] = checkUrl;
  } else {
    newUrl = `${API_URL}${url}`;
  }

  if (options) {
    if (options.method) {
      method = options.method.toLowerCase();
    }

    if (options.query) {
      newUrl = convertParamsToString(newUrl, options.query);
    }

    if (options.body) {
      body = options.body;
    }
  }

  try {
    if (API_URL === '') {
      throw new ApiUrlException('Server Error');
    }

    const init: Request = {
      method,
      headers: {
        Accept: 'application/json',
      },
    };

    if (body instanceof FormData) {
      init.body = body;
    } else {
      init.body = JSON.stringify(body);
      init.headers = {
        ...init.headers,
        'Content-Type': 'application/json',
      };
    }

    const response = await fetch(newUrl, init);
    const json = JSON.parse(await response.text());

    if (!response.ok) {
      throw new ApiUrlException(json);
    }

    if (!json) {
      return {};
    }

    return json;
  } catch (error) {
    if (error instanceof TypeError) {
      const networkError = `${error}`.toLowerCase();
      if (networkError.indexOf('networkerror') !== -1) {
        throw new Error('Check Internet Connectivity');
      }
    }

    throw safeJsonParse(error);
  }
};

export default request;
