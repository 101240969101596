import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { useRedirect, useRoutes } from 'hookrouter';
import React from 'react';
import ReactDOM from 'react-dom';
import NotFoundPage from './pages/NotFound';
import SearchPage from './pages/Search';
import * as serviceWorkerRegistration from './service-worker-registration';
import theme from './theme';

const Router = () => {
  useRedirect('/', '/search');
  const routes = useRoutes({ '/search': () => <SearchPage /> });

  return routes || <NotFoundPage />;
};

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

serviceWorkerRegistration.register();
